<template>
  <v-app>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
   metaInfo() {
    return { 
      title: "עם ישראל חי",
      meta: [
          { name: 'description', content:  'תפילות וסגולות מיוחדות'},
          { property: 'og:title', content: "עם ישראל חי"},
          { property: 'og:site_name', content: 'https://AmIsraeLive.com'},
          {property: 'og:type', content: 'website'},
          { vmid: 'og:image', property:'og:image', itemprop:'image', content:'https://amisraelive.com/img/logo.9d6a7594.png'},
          {name: 'robots', content: 'index,follow'} 
      ]
    }
  },
  data: () => ({
    //
  }),
};
</script>
